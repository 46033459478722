import Vue from 'vue'
import Router from 'vue-router'
// import VueGtag from "vue-gtag";
import DashboardLayout from '@/layout/DashboardLayout'
import BlogLayout from '@/layout/BlogLayout'
import {store} from './store'

Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'index',
      component: BlogLayout,
      children: [
        {
          path: '/index',
          name: 'index',
          component: () => import('./views/Blog/BlogIndex')
        }, {
          path: 'category/:name',
          name: 'category',
          component: () => import('./views/Blog/BlogIndex')
        }, {
          path: 'post/:alias',
          name: 'post',
          component: () => import('./views/Blog/BlogPost')
        }, {
          path: 'page/:alias',
          name: 'page',
          component: () => import('./views/Blog/BlogPost')
        }
      ]
    },
    {
      path: '/admin',
      redirect: '/admin/dashboard',
      component: DashboardLayout,
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import('./views/Dashboard/Dashboard.vue')
        }, {
          path: 'newPost',
          name: 'newPost',
          component: () => import('./views/Dashboard/NewPost')
        }, {
          path: 'editPost/:alias',
          name: 'editPost',
          component: () => import('./views/Dashboard/NewPost')
        }, {
          path: 'postManage',
          name: 'postManage',
          component: () => import('./views/Dashboard/PostManage')
        }, {
          path: 'categoryManage',
          name: 'categoryManage',
          component: () => import('./views/Dashboard/CategoryManage')
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.params.title ? to.params.title + '-LoneStack' : 'LoneStack';
  let path = to.path.split('/')[1]
  if (path !== undefined && path != null && path === 'admin') {
    if (!store.state.auth || store.state.group !== 0) {
      next('/')
    } else {
      next()
    }
  }
  next()
})

// Vue.use(VueGtag, {
//   config: {id: "G-MJCERZE4XL"},
//   pageTrackerTemplate(to) {
//     return {
//       page_title: to.fullPath,
//       page_path: to.fullPath
//     }
//   }
// }, router);

export default router
