<template>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <router-link class="nav-link nav-link-icon" to="/">
        <span class="nav-link-inner--text item-text">首页</span>
      </router-link>
    </li>

    <li class="nav-item" v-for="(cate, index) in cateList" :key="index">
      <router-link class="nav-link nav-link-icon" v-if="cate.is_show===1 && cate.type ===0"
                   :to="{ name: 'category', params: {name: cate.name, title: cate.name} }">
        <span class="nav-link-inner--text item-text">{{ cate.name }}</span>
      </router-link>
      <router-link class="nav-link nav-link-icon" v-if="cate.is_show===1 && cate.type ===1"
                   :to="{ name: 'page', params: {alias: cate.alias, title: cate.name} }">
        <span class="nav-link-inner--text item-text">{{ cate.name }}</span>
      </router-link>
    </li>

    <li class="nav-item" v-show="status">
      <!--      <base-dropdown class="option-btn" position="right">-->
      <!--        <base-button slot="title" type="secondary" size="sm" class="dropdown-toggle btn-neutral">-->
      <!--          {{ user.username }}-->
      <!--        </base-button>-->
      <base-dropdown>
        <div slot="title">
                <span class="avatar avatar-sm rounded-circle">
                  <img class="point" alt="Image placeholder" :src="avatarImg">
                </span>
          <div class="media-body ml-2 d-none d-lg-block">
          </div>
        </div>
        <div class=" dropdown-header noti-title">
          <h6 class="text-overflow m-0">{{ user.username }}</h6>
        </div>
        <router-link to="/profile" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>个人信息</span>
        </router-link>
        <router-link to="/admin" class="dropdown-item">
          <i class="ni ni-settings-gear-65"></i>
          <span>控制面板</span>
        </router-link>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item point" @click="logout">
          <i class="ni ni-user-run"></i>
          <span>注销</span>
        </a>
      </base-dropdown>
    </li>

    <li class="nav-item" v-show="!status">
      <base-button type="secondary" size="sm" class="option-btn btn-neutral" @click="showRegisterModal">注册</base-button>
      <base-button type="secondary" size="sm" class="option-btn btn-neutral" @click="showLoginModal">登录</base-button>
    </li>
  </ul>

</template>

<script>
import {getAllCategoryWithFixedPage} from "../../api";
import {Notify} from "../../utils";

export default {
  name: "blog-nav",
  props: {
    status: {
      type: Boolean,
      default: false,
      description: "登录状态 true=已登录, false=未登录"
    },
    user: {
      type: Object,
      default: () => ({}),
      description: "已登录用户信息"
    },
    showLoginModal: {
      type: Function,
      default: null,
      description: "展示登录框"
    },
    showRegisterModal: {
      type: Function,
      default: null,
      description: "展示注册框"
    },
    logout: {
      type: Function,
      default: null,
      description: "注销"
    }
  },
  data() {
    return {
      cateList: []
    };
  },
  created() {
    this.initCategoryList();
  },
  computed: {
    avatarImg() {
      return this.user.avatar === undefined ? null : "https://gravatar.loli.net/avatar/"+ this.user.avatar +"?d=identicon&s=50";
    }
  },
  methods: {
    initCategoryList() {
      getAllCategoryWithFixedPage().then(res => {
        if (res.data.code === 0) {
          this.cateList = res.data.data;
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    }
  }
};
</script>

<style scoped>
.item-text {
  font-size: 1.125rem;
}

.option-btn {
  margin-top: 15px;
}

.point {
  cursor: pointer;
}

.avatar {
  margin-top: 10px;
}
</style>