<template>
  <span>
    <span class="switch-label-left">{{ before }}</span>
    <label class="custom-toggle switch-label">
      <input type="checkbox"
             v-model="model"
             v-bind="$attrs"
             v-on="$listeners">
      <span class="custom-toggle-slider rounded-circle"></span>
    </label>
    <span class="switch-label-right">{{ after }}</span>
  </span>
</template>
<script>
  export default {
    name: "base-switch",
    inheritAttrs: false,
    props: {
      value: {
        type: Number,
        default: 1,
        description: "Switch value"
      },
      before: {
        type: String,
        default: "",
        description: "Before description."
      },
      after: {
        type: String,
        default: "",
        description: "After description."
      }
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      }
    }
  };
</script>
<style scoped>
  .switch-label {
    margin-bottom: -0.25rem;
  }
  /deep/ .switch-label-left {
    width: 80px;
    margin-bottom: 0.5rem;
    margin-right: 5px;
  }
  .switch-label-right {
    margin-bottom: 0.5rem;
    margin-left: 5px;
  }
</style>
