import axios from 'axios'

axios.defaults.withCredentials = true;

const AUTH_URL = 'https://www.lonestack.com:5000/auth'
const ADMIN_URL = 'https://www.lonestack.com:5000/admin'
const BLOG_URL = 'https://www.lonestack.com:5000/blog'

export function initAuth() {
  return axios.get(`${AUTH_URL}/initAuth`);
}

export function login(user) {
  return axios.post(`${AUTH_URL}/login`, user);
}

export function logout() {
  return axios.get(`${AUTH_URL}/logout`);
}

export function getCaptcha(emailInfo) {
  return axios.post(`${AUTH_URL}/getCaptcha`, emailInfo);
}

export function register(user) {
  return axios.post(`${AUTH_URL}/register`, user);
}

export function savePost(post) {
  return axios.post(`${ADMIN_URL}/savePost`, post);
}

export function getPosts(param) {
  return axios.post(`${BLOG_URL}/getPosts`, param);
}

export function postQueryList(param) {
  return axios.post(`${ADMIN_URL}/postQueryList`, param);
}

export function getPostById(id) {
  return axios.get(`${BLOG_URL}/post/${id}`);
}

export function getPostByAlias(alias) {
  return axios.get(`${BLOG_URL}/post/${alias}`);
}

//===============分类相关接口===============
export function getAllCategory() {
  return axios.get(`${BLOG_URL}/getAllCategory`);
}

export function getSimpleCategoryList() {
  return axios.get(`${BLOG_URL}/getSimpleCategoryList`);
}

export function getAllCategoryWithFixedPage() {
  return axios.get(`${BLOG_URL}/getAllCategoryWithFixedPage`);
}

export function getCategoryListWithPostCount() {
  return axios.get(`${ADMIN_URL}/getCategoryListWithPostCount`);
}

export function addCategory(category) {
  return axios.post(`${ADMIN_URL}/addCategory`, category);
}

export function editCategory(category) {
  return axios.post(`${ADMIN_URL}/editCategory`, category);
}

export function moveCategory(category) {
  return axios.post(`${ADMIN_URL}/moveCategory`, category);
}

export function categoryShowToggle(id, status) {
  return axios.get(`${ADMIN_URL}/categoryShowToggle/${id}/${status}`);
}

export function delCategory(id) {
  return axios.get(`${ADMIN_URL}/delCategory/${id}`);
}

export function getTagList() {
  return axios.get(`${BLOG_URL}/getTagList`);
}

export function getTagsWithPostCount() {
  return axios.get(`${BLOG_URL}/getTagsWithPostCount`);
}

export function getConfigByKey(key) {
  return axios.get(`${BLOG_URL}/config/${key}`);
}
