/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import BootstrapVue from 'bootstrap-vue'
import LoneSelect from 'lone-select'
import LoneMark from 'lone-mark'


import 'lone-select/dist/lone-select.css'
import 'lone-mark/dist/lone-mark.css'

Vue.config.productionTip = false

Vue.use(ArgonDashboard)
Vue.use(BootstrapVue)
Vue.use(LoneSelect)
Vue.use(LoneMark)
const vue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

export default vue;