<template>
  <div>
    <div class="form-group mb-0">
      <base-input
          placeholder="Search"
          class="input-group-alternative"
          alternative
          addon-right-icon="fas fa-search"
      ></base-input>
    </div>

    <card shadow header-classes="bg-transparent">
      <div slot="header">
        <span class="side-header-color h2 ls-1 mb-0">文章分类</span>
      </div>
      <div class="row">
        <div class="col">
          <div class="simple-list">
            <div v-for="(cate, index) in cateList" :key="index">
              <router-link :to="{ name: 'category', params: {name: cate.cateName, title: cate.cateName} }">
                <div class="simple-item" v-if="index%2===0">
                  <div class="simple-item-left">{{ cate.cateName }}</div>
                  <div class="simple-item-right">{{ cate.postCount }}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="simple-list">
            <div v-for="(cate, index) in cateList" :key="index">
              <router-link :to="{ name: 'category', params: {name: cate.cateName, title: cate.cateName} }">
                <div class="simple-item" v-if="index%2===1">
                  <div class="simple-item-left">{{ cate.cateName }}</div>
                  <div class="simple-item-right">{{ cate.postCount }}</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </card>
    <br>
    <card shadow header-classes="bg-transparent">
      <div slot="header">
        <span class="side-header-color h2 ls-1 mb-0">文章标签</span>
        <a class="link" v-if="tagMore && !expand" @click="expandAll"><i class="fas fa-caret-down text-green"></i></a>
        <a class="link" v-if="tagMore && expand" @click="fold"><i class="fas fa-caret-up text-green"></i></a>
      </div>
      <div>
        <tag v-for="(tag, index) in showTagList" :key="index" :name="tag.name" :count="tag.postCount"></tag>
      </div>
    </card>
    <br/>

<!--    <iframe v-show="musicSrc !== null" width="100%" :src="musicSrc"></iframe>-->
  </div>
</template>

<script>
import {getSimpleCategoryList, getTagsWithPostCount, getConfigByKey} from "@/api";
import {Notify} from "@/utils";
import Tag from "../../components/Tag";

export default {
  name: "side-bar",
  components: {Tag},
  data() {
    return {
      cateList: [],
      tagMore: false,
      expand: false,
      tagList: [],
      showTagList: [],
      musicSrc: null
    };
  },
  created() {
    this.initCategoryList();
    this.initTagList();
    this.initMusicConfig();
  },
  methods: {
    initCategoryList() {
      getSimpleCategoryList().then(res => {
        if (res.data.code === 0) {
          this.cateList = res.data.data;
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    initTagList() {
      getTagsWithPostCount().then(res => {
        if (res.data.code === 0) {
          this.tagList = res.data.data;
          if (this.tagList.length > 12) {
            this.showTagList = this.tagList.slice(0, 12);
            this.tagMore = true;
          } else {
            this.showTagList = this.tagList;
            this.tagMore = false;
          }
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    initMusicConfig() {
      getConfigByKey('BG_NETEASE_ID').then(res => {
        if (res.data.code === 0) {
          this.musicSrc = '//music.163.com/outchain/player?type=2&id=' + res.data.data.value + '&auto=0&height=66';
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    expandAll() {
      this.showTagList = this.tagList;
      this.expand = true;
    },
    fold() {
      this.showTagList = this.tagList.slice(0, 12);
      this.expand = false;
    }
  }
};
</script>

<style>
.side-header-color {
  color: #5e72e4;
}

.simple-list {
  width: 100%;
  margin: auto;
}

.simple-item {
  color: #5e72e4;
  height: 32px;
  line-height: 28px;
  cursor: pointer;
}

.simple-item:hover {
  color: #233dd2;
  height: 32px;
  line-height: 28px;
  cursor: pointer;
  border-radius: 6px;
  background-color: #f8f9fe;
}

.simple-item-left {
  margin-left: 10%;
  float: left;
}

.simple-item-right {
  margin-right: 10%;
  float: right;
}

.link {
  font-size: 20px;
  color: #00af91;
  cursor: pointer;
  word-break: keep-all;
  margin-left: 10px;
}


</style>