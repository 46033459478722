<template>
  <div class="tag">
    <div class="tag-name">
      {{ name }}
    </div>
    <div class="tag-count">
      {{ count }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    name: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    }
  }
}
</script>

<style scoped>
.tag {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  border-radius: 5px;
  background-color: whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  cursor: pointer;
  user-select: none;
}

.tag-name {
  height: 100%;
  border-radius: 5px 0 0 5px;
  display: inline-block;
  color: #f4f5f7;
  background-color: #2dce89;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
}

.tag-count {
  height: 100%;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}
</style>