const store = {
  debug: false,
  state: {
    siteName: '孤岛沉末',
    auth: false,
    group: null,
    user: {}
  },
  setSiteName(siteName) {
    this.state.siteName = siteName;
  },
  setAuth(auth) {
    this.state.auth = auth;
  },
  setGroup(group) {
    this.state.group = group;
  },
  setUser(user) {
    this.state.user = user;
  }
}

export {store}
