<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
        :background-color="sidebarBackground"
        short-title="Argon"
        title="Argon"
    >
      <template slot="links">
        <sidebar-item :link="{name: '博客概览', icon: 'ni ni-tv-2 text-primary', path: '/admin/dashboard'}"/>
        <sidebar-item :link="{name: '文章发布', icon: 'fas fa-pencil-alt text-green', path: '/admin/newPost'}"/>
        <sidebar-item :link="{name: '文章管理', icon: 'fas fa-file-alt text-info', path: '/admin/postManage'}"/>
        <sidebar-item :link="{name: '分类管理', icon: 'fas fa-cube text-pink', path: '/admin/categoryManage'}"/>
        <sidebar-item :link="{name: '博客前台', icon: 'fas fa-fan text-blue', path: '/index'}"/>

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import {FadeTransition} from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition
  },
  data() {
    return {
      sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
<style lang="scss">
</style>
