<template>
  <div class="main-content">
    <!-- Navbar -->
    <base-nav class="navbar-horizontal navbar-dark bg-gradient-success"
              containerClasses="px-4 container" expand>
      <router-link slot="brand" class="navbar-brand" to="/">
        <span class="display-4">{{ state.siteName }}</span>
      </router-link>

      <template v-slot="{closeMenu}">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <!-- <img src="img/brand/green.png"> -->
                <span class="display-4">{{ state.siteName }}</span>
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button type="button"
                      @click="closeMenu"
                      class="navbar-toggler"
                      aria-label="Toggle sidenav">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <blog-nav :status="state.auth" :user="state.user" :show-login-modal="showLogin"
                  :show-register-modal="showRegister" :logout="logout"/>
      </template>
    </base-nav>

    <div class="container ct-example-row mt--4">
      <div class="row">
        <div :class="{ 'col-md-8': sideHidden===0, 'col-md-12': sideHidden===1 }">
          <!-- 1.首页 2.文章 3.个人信息 -->
          <router-view></router-view>
        </div>

        <div class="col-md-4" v-show="!sideHidden">
          <side-bar/>
        </div>
      </div>
      <div class="aside">
        <div class="aside-block" v-show="scrollPercent > 30">
          <div class="cog" @click="scrollToTop">
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
          </div>
        </div>
        <div class="aside-block">
          <div class="cog" @click="toggleAside">
            <i class="fa fa-cog" aria-hidden="true"></i>
            <i class="fas fa-caret-right text-green option" v-show="asideSetting"></i>
          </div>
          <div class="set" v-show="asideSetting">
            <div class="row">
              <div class="col">
                <span>隐藏侧边栏</span>
              </div>
              <div class="col">
                <div class="form-group">
                  <base-switch :value="sideHidden" @click="switchSideHidden"></base-switch>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <content-footer v-if="!$route.meta.hideFooter"></content-footer>

    <!--    登录框-->
    <modal :show.sync="mShow.mLogin"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <template slot="header">
        <h2 class="modal-title text-center" id="loginModalLabel">登录</h2>
      </template>
      <card type="secondary" shadow
            body-classes="px-lg-5 py-lg-5"
            class="border-0">
        <template>
          <div class="text-center text-muted mb-4">
            <span>输入用户名密码登录:</span>
          </div>
          <form role="form">
            <base-input alternative required
                        v-model="loginForm.username"
                        class="mb-3"
                        placeholder="用户名"
                        addon-left-icon="ni ni-circle-08">
            </base-input>
            <base-input alternative required
                        v-model="loginForm.password"
                        type="password"
                        placeholder="密码"
                        addon-left-icon="ni ni-lock-circle-open">
            </base-input>
            <div class="text-center">
              <base-button type="primary" class="my-4" @click="login">登录</base-button>
            </div>
          </form>
        </template>
      </card>
    </modal>

    <!--    注册框-->
    <modal :show.sync="mShow.mRegister"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-sm">
      <template slot="header">
        <h2 class="modal-title text-center" id="registerModalLabel">注册</h2>
      </template>
      <card type="secondary" shadow
            body-classes="px-lg-5 py-lg-5"
            class="border-0">
        <template>
          <div class="text-center text-muted mb-4">
            <span>输入以下信息进行注册:</span>
          </div>
          <form role="form">
            <base-input alternative required
                        v-model="registerForm.username"
                        class="mb-3"
                        placeholder="用户名 (6-15位字母/数字)"
                        addon-left-icon="ni ni-circle-08">
            </base-input>
            <base-input alternative required
                        v-model="registerForm.email"
                        class="mb-3"
                        type="email"
                        placeholder="邮箱 (eg: example@gmail.com)"
                        addon-left-icon="ni ni-email-83">
            </base-input>
            <base-input alternative required
                        v-model="registerForm.password"
                        type="password"
                        placeholder="密码 (至少8位字母/数字/下划线)"
                        addon-left-icon="ni ni-lock-circle-open">
            </base-input>
            <base-input alternative required
                        v-model="registerForm.confirmPassword"
                        type="password"
                        placeholder="确认密码 (至少8位字母/数字/下划线)"
                        addon-left-icon="ni ni-lock-circle-open">
            </base-input>
            <div class="input-group mb-3">
              <input type="text" v-model="registerForm.captcha" class="form-control" placeholder="验证码">
              <div class="input-group-append">
                <base-button type="btn btn-sm btn-success" @click="getCaptcha">获取验证码</base-button>
              </div>
            </div>
            <div class="text-center">
              <base-button type="primary" class="my-4" @click="register">注册</base-button>
            </div>
          </form>
        </template>
      </card>
    </modal>
  </div>
</template>
<script>
import BlogNav from "../views/Blog/BlogNav";
import SideBar from "../views/Blog/SideBar";
import ContentFooter from './ContentFooter.vue';

import {initAuth, login, logout, register, getCaptcha, getConfigByKey} from "../api";
import {isEmpty, Notify, scrollToTop, getScrollPercent} from "../utils";
import {store} from "../store"

export default {
  name: 'blog-layout',
  components: {
    BlogNav,
    SideBar,
    ContentFooter
  },
  data() {
    return {
      state: store.state,
      asideSetting: false,
      sideHidden: 0,
      scrollPercent: 0,
      loginForm: {
        username: null,
        password: null
      },
      registerForm: {
        username: null,
        email: null,
        password: null,
        confirmPassword: null,
        captcha: null
      },
      mShow: {
        mLogin: false,
        mRegister: false
      },
      year: new Date().getFullYear(),
      showMenu: false
    }
  },
  computed: {
    postCol() {
      return this.sideHidden === 0 ? 8 : 12;
    }
  },
  created() {
    this.initSiteName();
    initAuth().then(res => {
      // console.log(res.data);
      if (res.data.code === 0) {
        store.setAuth(true);
        store.setGroup(res.data.data.group);
        store.setUser(res.data.data);
      }
    })
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    initSiteName() {
      getConfigByKey('SITE_NAME').then(res => {
        if (res.data.code === 0) {
          store.setSiteName(res.data.data.value);
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    handleScroll() {
      this.scrollPercent = getScrollPercent();
    },
    scrollToTop() {
      scrollToTop();
    },
    toggleAside() {
      this.asideSetting = !this.asideSetting;
    },
    switchSideHidden() {
      this.sideHidden = this.sideHidden === 0 ? 1 : 0;
      this.asideSetting = !this.asideSetting;
    },
    showLogin() {
      this.loginForm.username = null;
      this.loginForm.password = null;
      this.mShow.mLogin = true;
    },
    showRegister() {
      this.registerForm.username = null;
      this.registerForm.email = null;
      this.registerForm.password = null;
      this.registerForm.confirmPassword = null;
      this.registerForm.captcha = null;
      this.mShow.mRegister = true;
    },
    logout() {
      if (this.state.auth === false) {
        Notify.error("当前未登录, 请刷新重试!");
        return;
      }
      logout().then(res => {
        if (res.data.code === 0) {
          store.setAuth(false);
          store.setGroup(null);
          store.setUser({});
          Notify.success(res.data.msg);
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    login() {
      let message = this.validateLoginParam(this.loginForm);
      if (message != null) {
        Notify.error(message);
        return;
      }
      login(this.loginForm).then(res => {
        if (res.data.code === 0) {
          store.setAuth(true);
          store.setGroup(res.data.data.group);
          store.setUser(res.data.data);
          this.mShow.mLogin = false;
          Notify.success(res.data.data.username + ", 登录成功!")
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    validateLoginParam(form) {
      let xssPattern = /<script/;
      if (isEmpty(form.username) || isEmpty(form.password)) {
        return "用户名和密码不能为空!";
      }
      if (xssPattern.test(form.username) || xssPattern.test(form.password)) {
        return "请勿输入特殊标签:(";
      }
      return null;
    },
    getCaptcha() {
      if (isEmpty(this.registerForm.email)) {
        Notify.error("邮箱不能为空!")
        return;
      }
      getCaptcha(this.registerForm).then(res => {
        if (res.data.code === 0) {
          Notify.success(res.data.msg);
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    register() {
      let message = this.validateRegisterParam(this.registerForm);
      if (message != null) {
        Notify.error(message);
        return;
      }
      register(this.registerForm).then(res => {
        if (res.data.code === 0) {
          Notify.success(res.data.msg);
          this.mShow.mRegister = false;
          this.mShow.mLogin = true;
          this.loginForm.username = this.registerForm.username;
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    validateRegisterParam(form) {
      let usernamePattern = /^[a-zA-Z0-9]{6,15}$/;
      let passwordPattern = /^[a-zA-Z0-9_]{8,63}$/;
      let emailPattern = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      if (isEmpty(form.username)) {
        return "用户名不能为空!";
      } else if (!usernamePattern.test(form.username)) {
        return "用户名格式不正确!";
      }
      if (isEmpty(form.email)) {
        return "邮箱不能为空!";
      } else if (!emailPattern.test(form.email)) {
        return "邮箱格式不正确!";
      }
      if (isEmpty(form.password) || isEmpty(form.confirmPassword)) {
        return "密码不能为空!";
      } else {
        if (form.password != form.confirmPassword) {
          return "两次密码不一致!";
        }
        if (!passwordPattern.test(form.password)) {
          return "密码太短或包含非法字符!";
        }
      }
    }
  }
}
</script>
<style scoped>
/deep/ .container {
  max-width: 1280px;
}

.aside {
  position: fixed;
  z-index: 88;
  right: 0;
  bottom: 15%;
}

.aside-block {
  margin-top: 2px;
  padding: 6px 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px 0 0 10px;
  background-color: #fff;
}

.cog {
  cursor: pointer;
}

.option {
  margin-left: 5px;
}

.set {
  margin-left: 24px;
  width: 200px;
  font-size: 16px;
}

/*/deep/ .custom-checkbox .custom-control-input ~ .custom-control-label {*/
/*  cursor: pointer;*/
/*  font-size: 1rem;*/
/*}*/

</style>
