export function isEmpty(val) {
  return !(val ? typeof val === 'object' ? Array.isArray(val) ? val.length : Object.keys(val).length : true : false);
}

import context from '../main'

const Notify = {
  info(message) {
    context.$notify({
      type: "info",
      horizontalAlign: "center",
      message: message
    });
  },
  success(message) {
    context.$notify({
      type: "success",
      horizontalAlign: "center",
      message: message
    });
  },
  warn(message) {
    context.$notify({
      type: "warning",
      horizontalAlign: "center",
      message: message
    });
  },
  error(message) {
    context.$notify({
      type: "danger",
      horizontalAlign: "center",
      message: message
    });
  }
}
export {Notify}

export function formatTime(value) {
  if (value === 0) {
    return "-";
  }
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
}

export function formatDate(value) {
  if (value === 0) {
    return '-';
  }
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? ('0' + MM) : MM;
  let d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  return y + '-' + MM + '-' + d;
}

export function getTimeMillFromDateStr(dateStr) {
  let date = new Date(dateStr + " 00:00:00");
  return date.getTime();
}

export function getTodayDateStr() {
  let date = new Date();
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

export function getScrollPercent() {
  // 页面总高
  let totalH = document.body.scrollHeight || document.documentElement.scrollHeight;
  // 可视高
  let clientH = window.innerHeight || document.documentElement.clientHeight;
  // 计算有效高
  let validH = totalH - clientH;
  // 滚动条卷去高度
  let scrollH = document.body.scrollTop || document.documentElement.scrollTop;
  // 百分比
  return (scrollH / validH * 100).toFixed(2);
}